import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  messages,
} from 'src/constantes';
import {
  ReportableException,
  UnprocessableException,
} from '../exceptions';

const withNotification = WrappedComponent => (
  (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const onClose = (event, reason, key) => {
      if (reason === 'clickaway') {
        closeSnackbar(key);
      }
    };
    const appInfo = message => enqueueSnackbar(message, {
      variant: 'info',
      onClose: (event, reason, key) => onClose(event, reason, key),
    });
    const appSuccess = message => enqueueSnackbar(message, {
      variant: 'success',
      onClose: (event, reason, key) => onClose(event, reason, key),
    });
    const appWarning = message => enqueueSnackbar(message, {
      variant: 'warning',
      onClose: (event, reason, key) => onClose(event, reason, key),
    });
    const appError = (message, errorInfo) => {
      enqueueSnackbar(message, {
        variant: 'error',
        onClose: (event, reason, key) => onClose(event, reason, key),
      });
      // ToDo: Replace this with error logger.
      console.log(errorInfo);
    };
    const appNotify = message => enqueueSnackbar(message, {
      onClose: (event, reason, key) => onClose(event, reason, key),
    });

    const genericException = (exception) => {
      if (exception instanceof ReportableException) {
        appWarning(exception.message);
      } else if (exception instanceof UnprocessableException) {
        appWarning(exception.message);
      } else {
        appError(messages.dataFetch.fail, exception);
      }
    };
    return (
      <WrappedComponent
        appInfo={appInfo}
        appWarning={appWarning}
        appSuccess={appSuccess}
        genericException={genericException}
        appError={appError}
        appNotify={appNotify}
        {...props}
      />
    );
  }
);

export const propTypes = {
  appInfo: PropTypes.func.isRequired,
  appSuccess: PropTypes.func.isRequired,
  appWarning: PropTypes.func.isRequired,
  appError: PropTypes.func.isRequired,
  appNotify: PropTypes.func.isRequired,
};

export default withNotification;
