import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Layout>
                {route.routes
                  ? renderRoutes(route.routes)
                  : <Component {...props} />}
              </Layout>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    path: '/app',
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/configuracion/eps',
        component: lazy(() => import('src/views/configuracion/eps'))
      },
      {
        exact: true,
        path: '/app/configuracion/especialidades',
        component: lazy(() => import('src/views/configuracion/especialidades'))
      },
      {
        exact: true,
        path: '/app/configuracion/especialidades/new',
        component: lazy(() => import('src/views/configuracion/especialidades/New'))
      },
      {
        exact: true,
        path: '/app/configuracion/especialidades/edit/:value',
        component: lazy(() => import('src/views/configuracion/especialidades/Edit'))
      },
      {
        exact: true,
        path: '/app/configuracion/tipoafiliacion',
        component: lazy(() => import('src/views/configuracion/tipoAfiliacion'))
      },
      {
        exact: true,
        path: '/app/configuracion/diagnostico/new',
        component: lazy(() => import('src/views/configuracion/diagnostico/New'))
      },
      {
        exact: true,
        path: '/app/configuracion/diagnostico/edit/:value',
        component: lazy(() => import('src/views/configuracion/diagnostico/Edit'))
      },
      {
        exact: true,
        path: '/app/configuracion/diagnostico',
        component: lazy(() => import('src/views/configuracion/diagnostico/List'))
      },
      {
        exact: true,
        path: '/app/configuracion/institucion',
        component: lazy(() => import('src/views/configuracion/instituciones'))
      },
      {
        exact: true,
        path: '/app/configuracion/pacientes/new',
        component: lazy(() => import('src/views/configuracion/pacientes/New'))
      },
      {
        exact: true,
        path: '/app/configuracion/pacientes/edit/:value',
        component: lazy(() => import('src/views/configuracion/pacientes/Edit'))
      },
      {
        exact: true,
        path: '/app/configuracion/pacientes',
        component: lazy(() => import('src/views/configuracion/pacientes/List'))
      },
      {
        exact: true,
        path: '/app/configuracion/cirujanos/new',
        component: lazy(() => import('src/views/configuracion/cirujano/New'))
      },
      {
        exact: true,
        path: '/app/configuracion/cirujanos/edit/:value',
        component: lazy(() => import('src/views/configuracion/cirujano/Edit'))
      },
      {
        exact: true,
        path: '/app/configuracion/cirujanos',
        component: lazy(() => import('src/views/configuracion/cirujano/List'))
      },
      {
        exact: true,
        path: '/app/configuracion/fellow',
        component: lazy(() => import('src/views/configuracion/fellow'))
      },
      {
        exact: true,
        path: '/app/configuracion/subespecialidad',
        component: lazy(() => import('src/views/configuracion/subEspecialidad')),
      },
      {
        exact: true,
        path: '/app/configuracion/subespecialidad/new',
        component: lazy(() => import('src/views/configuracion/subEspecialidad/New')),
      },
      {
        exact: true,
        path: '/app/configuracion/subespecialidad/edit/:value',
        component: lazy(() => import('src/views/configuracion/subEspecialidad/Edit')),
      },
      {
        exact: true,
        path: '/app/configuracion/universidad',
        component: lazy(() => import('src/views/configuracion/universidad'))
      },
      {
        exact: true,
        path: '/app/operacion/prepedido/new',
        component: lazy(() => import('src/views/procesos/prepedido/New')),
      },
      {
        exact: true,
        path: '/app/operacion/prepedido/edit/:value',
        component: lazy(() => import('src/views/procesos/prepedido/Edit')),
      },
      {
        exact: true,
        path: '/app/operacion/prepedido',
        component: lazy(() => import('src/views/procesos/prepedido/List')),
      },
      {
        exact: true,
        path: '/app/operacion/asignacionsoportequirurgico/new',
        component: lazy(() => import('src/views/procesos/asignacionSoporteQuirurgico/New')),
      },
      {
        exact: true,
        path: '/app/operacion/asignacionsoportequirurgico/new/:uuid',
        component: lazy(() => import('src/views/procesos/asignacionSoporteQuirurgico/NewForm')),
      },
      {
        exact: true,
        path: '/app/operacion/asignacionsoportequirurgico/edit/:value',
        component: lazy(() => import('src/views/procesos/asignacionSoporteQuirurgico/Edit')),
      },
      {
        exact: true,
        path: '/app/operacion/asignacionsoportequirurgico',
        component: lazy(() => import('src/views/procesos/asignacionSoporteQuirurgico/List')),
      },
      {
        exact: true,
        path: '/app/operacion/reportegastos/new',
        component: lazy(() => import('src/views/procesos/reporteGastos/NewForm')),
      },
      {
        exact: true,
        path: '/app/operacion/reportegastos/new/:uuid',
        component: lazy(() => import('src/views/procesos/reporteGastos/New')),
      },
      {
        exact: true,
        path: '/app/operacion/reportegastos/edit/:uuid/:value',
        component: lazy(() => import('src/views/procesos/reporteGastos/Edit')),
      },
      {
        exact: true,
        path: '/app/operacion/reportegastos',
        component: lazy(() => import('src/views/procesos/reporteGastos/List')),
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        path: '/',
        component: () => <Redirect to="/test" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
