export default {
  asignacionSoporteQuirurgico: {
    base: 'asignacionsoportequirurgico',
    cambioEstado: 'asignacionsoportequirurgico/cambioestado',
    pedidos: 'asignacionsoportequirurgico/getpedidos',
  },
  autocompletes: {
    bodega: 'autocompletes/bodega',
    nomenclatura: 'autocompletes/nomenclatura',
    ciudad: 'autocompletes/ciudad',
    tipoIdentificacion: 'autocompletes/tipoidentificacion',
    heapVenta: 'autocompletes/heapventa',
    productosCompuestos: 'autocompletes/productoscompuestos',
    cliente: 'autocompletes/cliente',
    moneda: 'autocompletes/moneda',
    empleadoByRol: 'autocompletes/empleadobyrol',
    tiposAdjuntos: 'autocompletes/tiposadjuntos',
    pais: 'autocompletes/pais',
  },
  eps: {
    base: 'eps',
  },
  subespecialidades: {
    base: 'subespecialidad',
    fellow: {
      base: 'subespecialidadfellow',
    },
  },
  fellow: {
    base: 'fellow',
  },
  universidad: {
    base: 'universidad',
  },
  especialidades: {
    base: 'especialidad',
    subEspecialidad: {
      base: 'especialidadsubespecialidad',
    }
  },
  images: {
    base: 'images',
  },
  tipoAfiliacion: {
    base: 'tipoafiliacion',
  },
  diagnostico: {
    base: 'diagnostico',
    producto: {
      base: 'diagnostico/productos',
    },
  },
  instituciones: {
    base: 'instituciones',
  },
  pacientes: {
    base: 'pacientes',
    adjuntos: {
      base: 'pacienteadjunto',
    },
  },
  cirujanos: {
    adjuntos: {
      base: 'cirujanoadjunto',
    },
    base: 'cirujanos',
    ciudades: {
      base: 'cirujanos/ciudades',
    },
    firma: {
      base: 'cirujanofirma'
    },
    instituciones: {
      base: 'cirujanoinstitucion',
    },
  },
  prepedidos: {
    adjuntos: {
      base: 'prepedidoadjunto',
    },
    imagenes: {
      base: 'prepedidoimagen',
    },
    base: 'prepedido',
    cambioEstado: 'prepedido/cambioestado',
    productoscompuestos: 'prepedido/productoscompuestos',
    productosTerminados: 'prepedido/productosterminados',
    servicios: 'prepedido/servicios',
  },
  reporteGasto: {
    adjuntos: {
      base: 'reportegastoadjunto',
    },
    base: 'reportegasto',
    bioseguridad: {
      base: 'reportegastobioseguridad',
    },
    cambioEstado: 'reportegasto/cambioestado',
    composicion: 'reportegastocomposicion',
    imagenes: {
      base: 'reportegastoimagen',
    },
    firmas: {
      base: 'reportegastofirma',
    }
  },
  user: {
    base: 'user',
  },
};
