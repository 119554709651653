import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { withApi, withNotification } from 'src/wrappers';
import { endPoints } from 'src/constantes';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 0,
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    transition: 'margin 0.3s ease-in-out',
  },
  contentContainerOpen: {
    marginLeft: 300,
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({ children, doGet, genericException }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(true);
  const [user, setUser] = useState({});

  const init = async () => {
    try {
      const { allData = {} } = await doGet({
        url: endPoints.user.base
      });
      setUser(allData);
    } catch (e) {
      genericException(e);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(!isMobileNavOpen)}
        user={user}
        openMobile={isMobileNavOpen}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        user={user}
      />
      <div className={classes.wrapper}>
        <div className={clsx(classes.contentContainer, {
          [classes.contentContainerOpen]: isMobileNavOpen,
        })}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default withApi(withNotification(DashboardLayout));
