import React from 'react';
import ApiServiceFetch from 'src/services/ApiServiceFetch';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RequestExceptionFactory } from '../exceptions';

/**
 * This HOC allows to use a common interface to call api methods, using
 * the fetch service.
 */
export default WrappedComponent => withRouter((
  class extends React.PureComponent {
    /**
     * Allows to call a get request.
     */
    doGet = async data => this.doRequest('get', data);

    /**
     * Allows to call a post request.
     */
    doPost = async data => this.doRequest('post', data);

    /**
     * Allows to call a delete request.
     */
    doDelete = async data => this.doRequest('delete', data);

    /**
     * Allows to call a put request.
     */
    doPut = async data => this.doRequest('put', data);

    /**
     * All the request has the same behavour, we can handle this logic
     * inside a function that only returns the result.
     */
    doRequest = async (type, data) => {
      const initialResponse = await this.resolveFunc(type, data);
      if (initialResponse.ok) {
        if (initialResponse.status === 204) {
          return null;
        }
        return initialResponse.json();
      }
      throw await RequestExceptionFactory.fromHttpResponse(initialResponse);
    };

    /**
     * This function simplifyies the obtaining right method process from the
     * fetch service.
     */
    resolveFunc = async (type, data) => {
      let response;
      switch (type) {
        case 'post': response = await ApiServiceFetch.post(data);
          break;
        case 'put': response = await ApiServiceFetch.put(data);
          break;
        case 'delete': response = await ApiServiceFetch.delete(data);
          break;
        default: response = await ApiServiceFetch.get(data);
          break;
      }
      return response;
    };

    render() {
      return (
        <WrappedComponent
          doGet={this.doGet}
          doPost={this.doPost}
          doPut={this.doPut}
          doDelete={this.doDelete}
          {...this.props}
        />
      );
    }
  }
));

export const propTypes = {
  doPost: PropTypes.func,
  doGet: PropTypes.func,
  doDelete: PropTypes.func,
  doPut: PropTypes.func,
};
