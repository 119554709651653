import ReportableException from './ReportableException';
import UnprocessableException from './UnprocessableException';

export default class RequestExceptionFactory {
  static fromHttpResponse = async (httpResponse) => {
    const jsonResponse = await httpResponse.json();
    switch (httpResponse.status) {
      case 409:
        return new ReportableException(jsonResponse);
      case 422:
        return new UnprocessableException(jsonResponse);
      default:
        return Error(httpResponse.statusText);
    }
  }
}
